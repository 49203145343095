import React, { useEffect, useState } from 'react';
import { Script } from 'gatsby';
import { IS_PRODUCTION } from '../helpers/constants';
import { useStoreContext } from './Shop';
import storeUTMParams from '../helpers/storeUTMParams';
import { trackPageView } from '../components/useTrackingEvents';

// https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-script/
const RootElement = ({ children, path, location }) => {

	const { cart } = useStoreContext()
	const [cartId, setCartId] = useState(null)
	const [currentPath, setCurrentPath] = useState(null)
	
	useEffect(() => {
		if (cart?.id) {
			setCartId(cart.id)
		}
	}, [cart])

	useEffect(() => {
		if (path) {
			setCurrentPath(path)
		}
	}, [path])

	useEffect(() => {
		if (cartId && currentPath) {
			trackPageView(location, cart)
			storeUTMParams(location)
		}
	}, [cartId, currentPath])

  return IS_PRODUCTION ? (
    <>
			<Script id="gtm-init" async>
				{`
					(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
					new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
					j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
					'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
					})(window,document,'script','dataLayer','${process.env.GATSBY_GTAG_ID}');
				`}
			</Script>
			{/* Todo: Zendesk, Klaviyo, Facebook... */}
			<Script id="ze-snippet" src={`https://static.zdassets.com/ekr/snippet.js?key=a3f45d89-504b-41af-82d3-dcabaaed63ac`} strategy="post-hydrate" />
			<script src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=PfNXXK`} async />
			<script src={`https://www.klaviyo.com/media/js/public/klaviyo_subscribe.js`} async />
			{/* Content */}
      {children}
			{/* Other */}
			<noscript>
				<iframe 
					src={`https://www.googletagmanager.com/ns.html?id=${process.env.GATSBY_GTAG_ID}`} 
					height="0" 
					width="0" 
					title="gtm-noscript"
					style={{ 
						display: 'none', 
						visibility: 'hidden'
					}}>
				</iframe>
			</noscript>
    </>
  ) : children;
};

export default RootElement;