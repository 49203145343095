import React from "react"
import Root from "./src/wrappers/Root"
import { StoreProvider } from "./src/wrappers/Shop"

export const wrapRootElement = ({ element }) => {
	return (
		<StoreProvider>
			{element}
		</StoreProvider>
	)
}

export const wrapPageElement = ({ element, props }) => {
	return (
		<Root {...props}>
			{element}
		</Root>
	)
}