import React, { createContext, useContext, useState, useEffect, useCallback } from "react"
import { useStaticQuery, graphql } from "gatsby"
import useLocalStorage from "../hooks/useLocalStorage"
import log from "../helpers/log"
import { getTrackingAttributes } from "../components/useTrackingEvents"
import { useCart, LiveDataProvider, shopifyQuery } from "../components/Cart"
// const isBrowser = typeof window !== `undefined`

export const COUNTRY_KEY = `hl_country_code_v3`
export const COUNTRY_SELECTED_KEY = `hl_has_selected_v3`
export const US_ONLY_TAG = 'us_only'
export const UK_ONLY_TAG = 'uk_only'

function useContent() {

	// Query the data from the GraphQL API
	const data = useStaticQuery(graphql`
		{
			allContentfulRegion {
				nodes {
					name
					slug
					countryCodes
					symbol
					currencyCode
					shippingLocations
				}
			}
			allContentfulSizeTable {
				nodes {
					eu
					uk
					us
					cm
				}
			}
		}
	`)

	// Sort
	const [regions] = useState(data.allContentfulRegion.nodes)
	const [sizeConversionTable] = useState(data.allContentfulSizeTable.nodes)
	
	// Helpers
	const getRegionByCountry = (code) => regions.find(r => r.countryCodes.find(c => c === code)) || regions.find(r => r.slug === 'uk')
	const getRegionByCurrency = code => regions.find(r => r.currencyCode === code) || regions.find(r => r.slug === 'uk')
  
	// Return
	return { 
		regions, 
		getRegionByCountry, 
		getRegionByCurrency, 
		sizeConversionTable 
	}
}

export const StoreContext = createContext({})

export function useStoreContext() {
	const props = useContext(StoreContext)
	return props
}

export const StoreProvider = ({ children }) => {
	
	// New cart stuff...
	const {
		// State
		loading,
		// setLoading,
		didJustAddToCart,
		// setDidJustAddToCart,
		localCartId,
		cartIdReady,
		cart,
		// setCart,

		// Actions
		createCart,
		fetchCart,
		updateCartAttributes,
		addLineItem,
		updateLineItem,
		removeLineItem
	} = useCart()

	const [localCountryCode, setLocalCountryCode, countryCodeReady] = useLocalStorage(COUNTRY_KEY, 'GB')
	const [hasSelectedCountry, setHasSelectedCountry, hasSelectedCountryReady] = useLocalStorage(COUNTRY_SELECTED_KEY, false)
	const localStorageReady = countryCodeReady && cartIdReady
	const [shopInfo, setShopInfo] = useState(null)
	const [liveProducts, setLiveProducts] = useState([])
	const { regions, getRegionByCountry, sizeConversionTable } = useContent()
	const userCountry = localCountryCode
	const userCurrency = getRegionByCountry(userCountry)

	const initialiseShop = () => {
		if (localStorageReady) {
			if (localCartId) {
				fetchCart(localCartId, (existingCart) => {
					if (!existingCart) {
						createCart({
							countryCode: localCountryCode,
							attributes: getTrackingAttributes()
						})
					} else {
						updateCartAttributes({
							cartId: existingCart.id,
							attributes: getTrackingAttributes()
						})
					}
				})
			} else {
				createCart({
					countryCode: localCountryCode,
					attributes: getTrackingAttributes()
				})
			}
		}
	}
	useEffect(initialiseShop, [localStorageReady])

	const fetchLiveData = (country) => {
		log('running query: fetchLiveData()')
		setLiveProducts([])
		shopifyQuery(`
			query ProductsWithPrices @inContext(country: ${country}) {
				collectionByHandle(handle: "all-active-products-internal") {
					products(first: 250) {
						nodes {
							id
							title
							variants(first: 32) {
								nodes {
									id
									priceV2 {
										amount
										currencyCode
									}
									compareAtPriceV2 {
										amount
										currencyCode
									}
								}
							}
						}
					}
				}
				shop {
					shipsToCountries
				}
			}
		`).then((res) => {
			if (!res?.error) {
				setShopInfo(res.data?.shop)
				setLiveProducts(res.data?.collectionByHandle?.products?.nodes)
			} else {
				console.error(res.error)
			}
		})
	}
	useEffect(() => {
		fetchLiveData(localCountryCode)
	}, [localCountryCode])
	
	const updateCountry = (countryCode, callback) => {
		if (!countryCode) {
			console.error('Invalid country code')
			return
		}
		log('updating country...', countryCode)
		setHasSelectedCountry(true)
		createCart({ countryCode: countryCode }, () => {
			setLocalCountryCode(countryCode)
		})
		return
	}

	const resolveProductData = useCallback((products, isSingleEntry) => {
		const productsWithPrices = products.map(p => {
			const liveProductMatch = liveProducts?.find(item => item.id === p.shopifyId)
			
			if (liveProductMatch) {
				const variants = p.variants.map(v => {
					const liveVariantMatch = liveProductMatch.variants?.nodes?.find(item => item.id === v.shopifyId)
					return liveVariantMatch ? {
						...v,
						...liveVariantMatch
					} : v
				})
				return {
					...p,
					variants
				}
			} else {
				return p
			}
		})

		return isSingleEntry ? productsWithPrices[0] : productsWithPrices
	}, [liveProducts])

	const filterProductsByCountry = shopifyProductsWithTags => {
		return shopifyProductsWithTags?.filter(p => {
			// If userCountry is 'US' then check if product contains UK_ONLY_TAG; 
			// if userCountry is NOT 'US' check if product contains US_ONLY_TAG
			const flag = userCountry === 'US' ? UK_ONLY_TAG : US_ONLY_TAG
			const hasFlag = p?.tags?.find(t => t === flag)
			// If it does include the flag, then omit the product
			return !hasFlag
		}) || []
	}

  return (
		<StoreContext.Provider
			value={{
				userCountry,
				userCurrency,
				updateCountry,
				filterProductsByCountry,
				shopInfo,
				regions,
				getRegionByCountry,
				sizeConversionTable,
				liveProducts,
				localCountryCode,
				resolveProductData,
				hasSelectedCountry,
				hasSelectedCountryReady,
				setHasSelectedCountry,
				// Cart: State
				loading,
				didJustAddToCart,
				localCartId,
				cartIdReady,
				cart,
				// Cart: Actions
				createCart,
				fetchCart,
				updateCartAttributes,
				addLineItem,
				updateLineItem,
				removeLineItem
			}}
		>
			<LiveDataProvider>
				{children}
			</LiveDataProvider>
		</StoreContext.Provider>
  )
}

export function useRegionInPageContext(regionSlug) {
	const { updateCountry, hasSelectedCountry } = useStoreContext()
	useEffect(() => {
		// log(regionSlug)
		// const match = regionSlug ? regions.find(r => r.slug === regionSlug) : null
		// if (match && !userCountry) {
		// 	updateCountry(match.countryCodes[0])
		// }
		if (!hasSelectedCountry) {
			if (regionSlug === 'us') {
				updateCountry('US')
			} else if (regionSlug === 'uk') {
				updateCountry('GB')
			} else {
				// nothing, leave as default
			}
		}
	}, [regionSlug])
}