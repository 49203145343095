exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-info-contentful-page-slug-js": () => import("./../../../src/pages/info/{ContentfulPage.slug}.js" /* webpackChunkName: "component---src-pages-info-contentful-page-slug-js" */),
  "component---src-pages-stores-and-stockists-js": () => import("./../../../src/pages/stores-and-stockists.js" /* webpackChunkName: "component---src-pages-stores-and-stockists-js" */),
  "component---src-templates-collection-js": () => import("./../../../src/templates/collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-search-index-js": () => import("./../../../src/templates/search-index.js" /* webpackChunkName: "component---src-templates-search-index-js" */)
}

