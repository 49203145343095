import queryString from "query-string"
import { getLocalStorageItem, setLocalStorageItem } from '../hooks/useLocalStorage';
import log from "./log";

/*
	1. 	On the first visit save any utms and click ids present 
			in the url to local storage.
	
	2. 	On subsequent visits, if the user has new utms or a new gclid, 
			clear the existing utms and gclid (but leave any other click ids) 
			and replace them with whatever is present.
	
	3. 	If in a subsequent visit a click id other than the gclid is present 
			(fbclid, ttclid, ...) **without accompanying utms**, save this value 
			without making changes to the existing parameters.
	
	4. 	If in a subsequent visit a click id other than the gclid is present 
			(fbclid, ttclid) **AND** new utm values **ARE** present clear the utm 
			values and gclid, and save the new click id and utms.

*/

const KEY = 'hl_utms'

const UTMS = [
	'utm_source', 
	'utm_medium', 
	'utm_campaign', 
	'utm_content', 
	'utm_term',
]
const CLIDS = [
	'gclid', 
	'fbclid',
	'ttclid',
	'irclickid'
]

function getUTMParams(uri) {
	const params = queryString.parse(uri)
	// Clear unspecified params
	Object.entries(params).forEach(([key, value]) => {
		const isValidKey = [...UTMS, ...CLIDS].find(e => e === key)
		if (!isValidKey) {
			delete params[key]
		}
	})
	return params
}

export default function storeUTMParams(location) {
	if (location?.search) {
		const isTracked = [...UTMS, ...CLIDS].find(x => location.search.includes(x))
		
		if (isTracked) {
			const oldParams = getLocalStorageItem(KEY)
			const oldClid = oldParams && CLIDS.find(x => oldParams[x])
			const newParams = getUTMParams(location.search)
			const newClid = CLIDS.find(x => newParams[x])
			const hasNewUTMS = UTMS.find(x => newParams[x])
			const hasNewClid = newClid !== oldClid
			let updatingParams = newParams

			/*
				Eg. Scenario 4
    			1. First visit `gclid=xxxx&utm_source=google&utm_medium=cpc&utm_term=button`
    			2. Second visit `fbclid=xxxx`
    			3. Final result in local storage `gclid=xxxx&utm_source=google&utm_medium=cpc&utm_term=button&fbclid=xxxx`
        
        	NB. note that both `gclid` and `fbclid` are present.
			*/
			if (hasNewClid && !hasNewUTMS) {
				updatingParams = {
					...oldParams,
					...newParams
				}
			}

			setLocalStorageItem(KEY, updatingParams)
			log('utms in localStorage...', getLocalStorageItem(KEY))
		}
	}
}

// function storeUTMParams_old(location) {
// 	if (location?.search) {
// 		const oldParams = getLocalStorageItem(KEY)
// 		const newParams = getUTMParams(location.search)
// 		let updatingParams = {}

// 		console.log(oldParams)

// 		if (oldParams) {
// 			// Subsequent visits
// 			let diff = []
// 			Object.entries(newParams).forEach(([key, value]) => {
// 				if (oldParams[key] && oldParams[key] !== value) {
// 					diff.push({ key, value })
// 				}
// 			})
// 			console.log(newParams)
// 			const hasNewUTM = diff.find(d => d.key.includes('utm_'))
// 			const hasNewGclid = diff.find(d => d.key === 'gclid')
// 			const hasNewClickId = CLIDS.find(c => newParams[c] !== oldParams[c]) ? true : false

// 			if (hasNewUTM || hasNewGclid) {
// 				/*
// 					On subsequent visits, if the user has new utms or a new gclid, 
// 					clear the existing utms and gclid (but leave any other click ids) 
// 					and replace them with whatever is present.
// 				*/
// 				updatingParams = newParams

// 				// Attach any previous click ids (other than gclid)
// 				CLIDS.forEach(c => {
// 					if (oldParams[c] && c !== 'gclid') {
// 						updatingParams[c] = oldParams[c]
// 					}
// 				})

// 				console.log('hello?')
// 				if (!hasNewGclid) {
// 					console.log(updatingParams)
// 				}

// 				// console.log('scenario 2 + 3', updatingParams)
// 				setLocalStorageItem(KEY, updatingParams)
// 			} else if (hasNewClickId) {
// 				// Difference might be other click ids (fbclid etc)
// 				// todo
// 				updatingParams = {
// 					...oldParams,
// 					...newParams
// 				}
// 				// console.log("scenario 4?", updatingParams)
// 				setLocalStorageItem(KEY, updatingParams)
// 			}
// 		} else {
// 			/* 
// 				On the first visit save any utms and click ids 
// 				present in the url to local storage. 
// 			*/
// 			// console.log('scenario 1', newParams)
// 			setLocalStorageItem(KEY, newParams)
// 		}

// 		console.log(localStorage[KEY])
// 	}
// }