import React, { useState } from "react"
import { createClient, Provider as UrqlProvider } from "urql"
import useLocalStorage from "../hooks/useLocalStorage"
import log from "../helpers/log"

const CART_ID_KEY = `hl_cart_id_v1`

const CART_FRAGMENT = `
	id
	checkoutUrl
	totalQuantity
	cost {
		totalAmount {
			amount
			currencyCode
		}
	}
	attributes {
		key
		value
	}
	lines(first: 200) {
		edges {
			node {
				id
				quantity
				attributes {
					key
					value
				}
				merchandise {
					... on ProductVariant {
						id
						sku
						title
						quantityAvailable
						price {
							amount
							currencyCode
						}
						compareAtPrice {
							amount
							currencyCode
						}
						product {
							id
							title
							handle
							vendor
							featuredImage {
								url
								width
								height
							}
							tags
						}
						selectedOptions {
							name
							value
						}
					}
				}
			}
		}
	}
`

export const urqlClient = createClient({
  url: `https://${process.env.GATSBY_SHOPIFY_STORE_URL}/api/2022-10/graphql.json`,
  fetchOptions: {
    headers: {
      "X-Shopify-Storefront-Access-Token":
        process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
    },
  },
})

export async function shopifyQuery(query, variables) {
	const data = await urqlClient
		.query(query, variables)
		.toPromise()
	
	return data
}

export async function shopifyMutation(mutation, variables) {
	const data = await urqlClient
		.mutation(mutation, variables)
		.toPromise()
	
	return data
}

export function LiveDataProvider ({ children }) {
	return (
		<UrqlProvider value={urqlClient}>
			{children}
		</UrqlProvider>
	)
}

export function useCart() {

	const [loading, setLoading] = useState(true)
  const [didJustAddToCart, setDidJustAddToCart] = useState(false)
	const [localCartId, setLocalCartId, cartIdReady] = useLocalStorage(CART_ID_KEY)
	const [cart, setCart] = useState({ loading: true })

	const createCart = (options, callback) => {
		log('running query: createCart()')

		const CREATE_CART_QUERY = `
			mutation createCart($input: CartInput!) {
				cartCreate(input: $input) {
					cart {
						${CART_FRAGMENT}
					}
				}
			}
		`;

		const variables = {
			input: {
				attributes: options.attributes,
				buyerIdentity: {
					countryCode: options.countryCode
				}
			}
		}

		shopifyMutation(CREATE_CART_QUERY, variables)
			.then((res) => {
				setLoading(false)
				if (!res?.error) {
					const cartData = res.data?.cartCreate?.cart
					setCart(cartData)
					setLocalCartId(cartData?.id)
					callback && callback(cartData)
				} else {
					console.error(res.error)
				}
			})
	}

	const fetchCart = (cartId, callback) => {
		log('running query: fetchCart()')

		const FETCH_CART_QUERY = `
			query fetchCart($id: ID!) {
				cart (
					id: $id
				) {
					${CART_FRAGMENT}
				}
			}
		`;

		const variables = {
			id: cartId
		}

		shopifyQuery(FETCH_CART_QUERY, variables)
			.then((res) => {
				setLoading(false)
				if (!res?.error) {
					const cartData = res.data?.cart
					setCart(cartData)
					setLocalCartId(cartData?.id)
					callback && callback(cartData)
				} else {
					console.error(res.error)
				}
			})
	}

	const updateCartAttributes = ({ cartId, attributes }) => {
		log('running query: updateCartAttributes()')

		const UPDATE_CART_QUERY = `
			mutation cartAttributesUpdate(
				$attributes: [AttributeInput!]!, 
				$cartId: ID!
			) {
				cartAttributesUpdate(attributes: $attributes, cartId: $cartId) {
					cart {
						${CART_FRAGMENT}
					}
					userErrors {
						field
						message
					}
				}
			}
		`

		const variables = {
			cartId,
			attributes
		}

		shopifyMutation(UPDATE_CART_QUERY, variables)
			.then((res) => {
				if (!res?.error) {
					const cartData = res.data?.cartAttributesUpdate?.cart
					setCart(cartData)
					setLocalCartId(cartData?.id)
				} else {
					console.error(res.error)
				}
			})
	}

	const addLineItem = (line, callback) => {
		log('running query: addLineItem()')

		const ADD_LINE_ITEM_QUERY = `
			mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
				cartLinesAdd(cartId: $cartId, lines: $lines) {
					cart {
						${CART_FRAGMENT}
					}
					userErrors {
						field
						message
					}
				}
			}
		`;
		
		const variables = {
			cartId: cart.id,
			lines: [line]
		};

		setLoading(true)

		shopifyMutation(ADD_LINE_ITEM_QUERY, variables)
			.then((res) => {
				if (!res?.error) {
					const cartData = res.data?.cartLinesAdd?.cart
					setCart(cartData)
					setLoading(false)
					setDidJustAddToCart(true)
					callback && callback(cartData)
					setTimeout(() => setDidJustAddToCart(false), 3000)
				} else {
					console.error(res.error)
					callback && callback(null, res.error)
					setLoading(false)
				}
      })
			.catch((error) => {
				console.error(error)
				callback && callback(null, error)
				setLoading(false)
			})
	}

	const updateLineItem = (lineItemId, quantity, callback) => {
		log('running query: updateLineItem()')

		const UPDATE_LINE_ITEM_QUERY = `
			mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
				cartLinesUpdate(cartId: $cartId, lines: $lines) {
					cart {
						${CART_FRAGMENT}
					}
					userErrors {
						field
						message
					}
				}
			}
		`;

		const variables = {
			cartId: cart.id,
			lines: [{
				id: lineItemId,
				quantity
			}]
		};

		shopifyMutation(UPDATE_LINE_ITEM_QUERY, variables)
			.then((res) => {
				if (!res?.error) {
					const cartData = res.data?.cartLinesUpdate?.cart
					setCart(cartData)
					setLoading(false)
					setDidJustAddToCart(true)
					callback && callback(cartData)
					setTimeout(() => setDidJustAddToCart(false), 3000)
				} else {
					console.error(res.error)
					callback && callback(null, res.error)
					setLoading(false)
				}
      })
			.catch((error) => {
				console.error(error)
				callback && callback(null, error)
				setLoading(false)
			})
	}

	const removeLineItem = (lineItemId) => {
		updateLineItem(lineItemId, 0)
	}

	return {
		// State
		loading,
		setLoading,
		didJustAddToCart,
		setDidJustAddToCart,
		localCartId,
		cartIdReady,
		cart,
		setCart,

		// Actions
		createCart,
		fetchCart,
		updateCartAttributes,
		addLineItem,
		updateLineItem,
		removeLineItem
	}
}