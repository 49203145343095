import { useEffect } from "react"
import moment from "moment"
import { v4 as uuid } from 'uuid'
import Cookies from 'js-cookie'
import { getLocalStorageItem } from "../hooks/useLocalStorage"
import log from "../helpers/log"
import { IS_PRODUCTION, IS_BROWSER, BRAND } from "../helpers/constants"

export const getCategoryFromTags = tags => tags?.filter(t => !t.includes('Filter:') && !t.includes('Myles')).join(',')
export const getVariantId = str => str?.split('/ProductVariant/')?.[1]
export const getProductId = str => str?.split('/Product/')?.[1]

function getDevice() {
	return IS_BROWSER ? {
		screen_resolution: window?.screen ? `${window.screen.width}x${window.screen.height}` : "",
		viewport_size: window?.innerWidth ? `${window.innerWidth}x${window.innerHeight}` : "",
		encoding: document?.characterSet || "",
		language: window?.navigator?.language || "",
		colors: window?.screen ? `${window.screen.colorDepth}-bit` : ""
	} : {}
}

function getUserId() {
	const cookies = Cookies.get()
	if (cookies._hl_uuid) {
		return cookies._hl_uuid
	} else {
		const _hl_uuid = uuid()
		Cookies.set('_hl_uuid', _hl_uuid, { expires: 365 })
		return _hl_uuid
	}
}

function getUser() {
	return {
		user_consent: "",
		visitor_type: "guest",
		user_id: getUserId()
	}
}

function useDelayedEffect(callback, deps=[], delay=300) {
	useEffect(() => {
		const timer = setTimeout(callback, delay)
		return () => clearTimeout(timer)
	}, deps)
}

export function getMarketingData() {
	const cookies = Cookies.get()

	let data = {
		// This is the GA4 cookie ID. The XXX... portion of the cookie will differ for every client
		"_ga_XXXXXXXXXX": "todo", // GA4 Cookie ID
    "_fbp": null, // FB cookie id
	  "_fbc": null, // FB cookie id if available
    "_ga": null, // GA cookie id
	  "_gaexp": null, // Optimize cookie id if available
		"_gid": null, // GA cookie id if available
		"__utma": null, // GA cookie id if available
	  "ttclid": null, // TikTok cookie ID if using TikTok
		"crto_mapped_user_id": null, // Criteo cookie id if using Criteo
		"crto_is_user_optout": null, // Criteo opt out status
		"user_id": getUserId(), // UUID uniqe per user, should be persisted as long as possible and kept consistent between sessions
	}

	Object.entries(data).forEach(([key, value]) => {
		if (cookies[key]) {
			data[key] = cookies[key]
		} else {
			delete data[key]
		}
	})

	Object.entries(cookies).find(([key, value]) => {
		const [foo, bar] = key?.split('_ga_') || []
		if (bar) {
			data[key] = value
		}
	})

	data.user_id = getUserId()

	return data
}

export function track(eventLabel="", eventData={}, cart, sendUserData, eventDelay=10) {

	if (IS_BROWSER && IS_PRODUCTION && window.dataLayer && cart) {

		function pushDataLayer() {
			window.dataLayer.push(...arguments)
		}

		const user_properties = getUser()
		const marketing = getMarketingData()
		const now = moment().format()
		const userData = {
			"event_id": uuid(), // unique uuid for FB conversion API
			"event_time": now, // Timestamp for the event
			"user_properties": user_properties, // See user properties object below
			"marketing": marketing, // See the marketing object
		}

		const cartData = cart ? {
			cart_total: cart.cost?.totalAmount?.amount,
			ecommerce: {
				cart_contents: {
					products: cart.lines?.edges?.map(({ node }) => {
						const variant = node.merchandise
						const product = variant?.product
						const list = node.attributes?.find(a => a.key === 'Collection')?.value
						return {
							id: variant?.sku, // SKU
							name: product?.title, // Product title
							brand: BRAND,
							category: product.vendor,
							variant: variant?.title,
							price: variant?.price?.amount,
							quantity: `${node.quantity}`,
							list: list || "", // The list the product was discovered from or is displayed in
							product_id: getProductId(product?.id), // The product_id
							variant_id: getVariantId(variant?.id), // id or variant_id
							compare_at_price: variant?.compareAtPrice?.amount || "0.0", // "0.0" is ok 
							image: product?.featuredImage?.url, // If available, otherwise use an empty string
						}
					}),
					currencyCode: cart.cost?.totalAmount?.currencyCode
				}
			}
		} : {}

		// dl_user_data first...
		if (sendUserData) {
			const userEventData = {
				event: "dl_user_data",
				"device": getDevice(), // See device object below
				"page": { title: document.title }, // page title
				...userData,
				...cartData
			}
			pushDataLayer({
				...userEventData,
				eventCallback: (e) => {
					log("***dl_user_data***", userEventData)
				}
			})
		}
		

		// Then the selected event...
		const allData = {
			event: eventLabel,
			// Data for all events
			...userData,
			// Specific event data
			...eventData,
			// Callback?
			eventCallback: (e) => {
				log(eventLabel, allData)
			}
		}
		setTimeout(() => {
			pushDataLayer(allData)
		}, eventDelay)
	} 
}

export function trackPageView(location, cart) {
	const page_location = location ? location.href : window.location.href
	track("page_view", { page_location }, cart, true); // true = sendUserData
	track("dl_route_change", { page_location }, cart);
}

export function useTrackViewItem(data={}, deps=[]) {
	useDelayedEffect(() => {
		track("dl_view_item", {
			ecommerce: {
				currencyCode: data.currencyCode,
				detail: {
					actionField: { list: data.list, action: "detail" },
					products: data.products
				}
			}
		}, data.cart)
	}, deps)
}

export function trackNewsletterSubscribe(data={}) {

	track("dl_subscribe", {
		...data
	})
}

export function trackAddToCart(data={}) {

	track("dl_add_to_cart", {
		ecommerce: {
			currencyCode: data.currencyCode,
			add: {
				actionField: { list: data.list || "" },
				products: data.products
			}
		}
	}, data.cart)
}

export function trackRemoveFromCart(data={}) {

	track("dl_remove_from_cart", {
		ecommerce: {
			currencyCode: data.currencyCode,
			remove: {
				actionField: { list: data.list || "", action: "add" },
				products: data.products
			}
		}
	}, data.cart);

}

export function trackViewCart(data={}) {
	
	track("dl_view_cart", {
		cart_total: data.cart_total,
		ecommerce: {
			currencyCode: data.currencyCode,
			actionField: { list: "Shopping cart" },
			impressions: data.impressions
		}
	}, data.cart);
}

export function useTrackViewCart(data={}, deps=[]) {
	useDelayedEffect(() => {
		if (data) {
			trackViewCart(data)
		}
		return
	}, [...deps])
}

export function trackViewItemList(data={}, cart) {

	const impressions = data.products?.map((p, i) => {
		const variant = p.variants?.[0]
		// const compareAtPriceAmount = variant ? parseInt(variant?.compareAtPriceV2?.amount) : 0
		return {
			id: variant?.sku, // SKU
			name: p.title, // Product title
			brand: BRAND,
			category: p?.vendor,
			price: variant?.priceV2?.amount,
			list: `/collections/${data.item_list_id}`, // The list the product was discovered from or is displayed in
			variant: variant.title,
			product_id: getProductId(p.shopifyId), // The product_id
			variant_id: getVariantId(variant.shopifyId), // id or variant_id
			compare_at_price: variant?.compareAtPriceV2?.amount || "0.0", // "0.0" is fine
			image: p.media?.[0]?.thumbnail?.images?.fallback?.src, // If available, otherwise use an empty string
			position: i+1, // position in the list of search results, collection views and position in cart indexed starting at 1
		}
	})

	track("dl_view_item_list", {
		ecommerce: {
			currencyCode: data.currencyCode,
			impressions
		}
	}, cart);
}

export function useTrackViewItemList(data={}, deps=[], ready, cart) {
	useDelayedEffect(() => {
		if (ready) {
			trackViewItemList(data, cart)
		}
		return
	}, [...deps, ready])
}

export function trackSelectItem(data={}) {
	track("dl_select_item", {
		ecommerce: {
			currencyCode: data.currencyCode,
			click: {
				actionField: { list: data.listPathname, action: 'click' },
				products: data.products
			}
		}
	}, data.cart, false, 0);
}

export function trackSearch(data={}) {

	const impressions = data.products?.filter((p, i) => i < 50)?.map((p, i) => {
		const variant = p.variants?.[0]
		return {
			id: variant?.sku, // SKU
			name: p.title, // Product title
			brand: BRAND,
			category: p?.vendor,
			price: variant?.priceV2?.amount,
			product_id: getProductId(p.shopifyId), // The product_id
			variant_id: getVariantId(variant.shopifyId), // id or variant_id
			compare_at_price: variant?.compareAtPriceV2?.amount || "0.0", // If available 
			image: p.media?.[0]?.thumbnail?.images?.fallback?.src, // If available, otherwise use an empty string
			position: i+1, // position in the list of search results, collection views and position in cart indexed starting at 1
		}
	})

	track("dl_view_search_results", {
		search_term: data.search_term,
		ecommerce: {
			currencyCode: data.currencyCode,
			actionField: { "list": "search results" },
			impressions: impressions
		}
	}, data.cart);
}

// https://www.notion.so/elevar/Collecting-and-Storing-UTM-Parameters-and-Click-IDs-on-Headless-Sites-92575d2632d643a08d1b5bde3900faee
export function getTrackingAttributes () {
	const storedUTMS = getLocalStorageItem('hl_utms')
	const cookies = Cookies.get()

	const hl_utms = storedUTMS ? [{
		key: "_elevar_visitor_info",
		value: JSON.stringify(storedUTMS)
	}] : []
	const fbp_cookie = cookies._fbp ? [{
		// Facebook cookie information
		"key": "_elevar__fbp",
		"value": cookies._fbp // Found in site cookie _fbp
	}] : []
	const fbc_cookie = cookies._fbc ? [{
		// Facebook cookie information
		"key": "_elevar__fbc",
		"value": cookies._fbc // Found in site cookie _fbc
	}] : []
	const ga_cookie = cookies._ga ? [{
		// GA cookie information
		"key": "_elevar__ga",
		"value": cookies._ga // Found in site cookie _ga
	}] : []
	const ga4_key = Object.keys(cookies).find(c => c.includes('_ga_'))
	const ga4_value = cookies[ga4_key]
	const ga4_cookie = ga4_key ? [{
		"key": `_elevar_${ga4_key}`,
		"value": ga4_value
	}] : []

	const note_attributes = [
		...hl_utms,
		...fbp_cookie,
		...fbc_cookie,
		...ga_cookie,
		...ga4_cookie
	]

	return note_attributes
}